﻿$navbar-brand-image-height: 25px + 6px + 6px; // prevent nav-bar from changing height when only logo is visible and when nav-items are next to it
$navbar-brand-image-width: 170px;

.navbar-brand[title='IMMOunited GmbH'] {
    height: $navbar-brand-image-height;
    width: $navbar-brand-image-width;
    background-image: url(/img/Immounited-SSO.png);
    background-image: linear-gradient(transparent, transparent), url(/img/Immounited-SSO.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100%;
}