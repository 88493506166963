﻿.field-validation-error {
    color: #b94a48;
    //font-weight: bold;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #b94a48;
}

/*.validation-summary-errors {
    color: #b94a48;
    border-color: #b94a48;
    background-color: #fff4f6;
}

.validation-summary-valid {
    display: none;
}*/
