﻿@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato-Regular.eot?") format("eot");
  src: local('☺'), 
      url("/fonts/Lato-Regular.woff2") format("woff2"), 
      url("/fonts/Lato-Regular.woff") format("woff"), 
      url("/fonts/Lato-Regular.ttf") format("truetype"), 
      url("/fonts/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Condensed.eot") format("eot");
  src: local('☺'),       
      url("/fonts/Roboto-Condensed.woff") format("woff"), 
      url("/fonts/Roboto-Condensed.ttf") format("truetype"), 
      url("/fonts/Roboto-Condensed.svg#Roboto-Condensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-BoldCondensed.eot") format("eot");
  src: local('☺'),       
      url("/fonts/Roboto-BoldCondensed.woff") format("woff"), 
      url("/fonts/Roboto-BoldCondensed.ttf") format("truetype"), 
      url("/fonts/Roboto-BoldCondensed.svg#Roboto-BoldCondensed") format("svg");
  font-weight: bold;
  font-style: normal;
}