@import "fonts";

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Your variable overrides
@import "immounited-variables";

// Bootstrap and its default variables

// Optional
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";

@import "form-validation";
@import "nav";

html, body, .background-cover {
    height: 100%;
}

.background-cover {
    //margin-top: 60px;
    //padding-bottom: 40px;
    padding: 60px 15px 15px 15px;
    background-image: url(/img/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: uppercase
}

label {
    font-weight:600;
}

.page-header {
    color: $info;
    padding-bottom: 0;
    margin: 0 0 6px 0 !important;
    border-bottom: none;
    font-weight: normal;
    font-size: 22px;
    // iPhone 5
    @media(width: 320px) {
        margin-bottom: 8px;
        padding-bottom: 4px;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: none;
    }
}

.page-content {
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 3px 9px rgba(0,0,0,.4);
    background-clip: padding-box;
    background-color: rgb(248, 245, 240);
}

.logo-product {
    max-width: 100%;
    min-height: 19px;
    height: 19px;
    margin: 6px 0px;
}

.product-links {
    padding-top: 1em;
}

.disclaimer {
    text-align: center;
    font-size: smaller;
    padding-top: 12px
}

.welcome-page .logo {
    width: 64px;
  }

.welcome-page {
    li {
        list-style: none;
        padding: 4px;
    }
}

.logged-out-page {
    iframe {
        display: none;
        width: 0;
        height: 0;
    }
}

.grants-page {
    .card {
        margin-top: 20px;
        border-bottom: 1px solid lightgray;

        .card-title {
            img {
                width: 100px;
                height: 100px;
            }

            font-size: 120%;
            font-weight: bold;
        }

        label {
            font-weight: bold;
        }
    }
}


