﻿$immounited: #006b55;
$imabis: #EF7E19;

$immobase: #a1d884;
$immostats: #71CC98;
$immomapping: #64ccc9;
$immovaluation: #7ba4db;
$immotrends: #00afd7;
$immocheck: #00966c;

$gray-base: #353943;
$gray-darker: #707372;
$gray-dark: #97999b;
$gray: #b8b8b8;
$gray-light: #cdcccd;
$gray-lighter: #ebebeb;

$primary: $immounited;
$success: lighten(#6cc24a, 5%);
$info: #b8b8b8;
$warning: #ea7600;
$danger: #ff1d25;

$theme-colors: map-merge(
    $theme-colors,
    (
        "primary": $primary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger
    )
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

$font-family-sans-serif: 'Lato', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.875rem; // ~14px;

$input-btn-font-size: $font-size-base;
$btn-font-size: $font-size-base;
$form-group-margin-bottom: 8px;

$h1-font-size: $font-size-base * 1.7;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.9;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: 'Roboto', sans-serif;
$headings-font-weight: normal;
$headings-line-height: 1;

$card-spacer-x: 0.5rem;
